







































































































































































































































































































































































































@import '~@/assets/styles/components/formularios';
@import '~@/assets/styles/components/modal-cadastro';

.autocomplete {
  input {
    font-family: 'Roboto Mono', monospace;
  }
}

.v-autocomplete__content {
  .v-list-item__title {
    font-family: 'Roboto Mono', monospace;
  }
}
